.info__header-logo {
   padding-top: 1rem;
   padding-bottom: 0.8125rem;
}

.info__header-logo svg{
   fill: var(--bg-light);
   height: 60px;
   width: fit-content;

   /* Test */
   height: 6.25rem;
   margin-top: 5rem;
}

/* Css for hide license */
.footer .info__content.license {
   display: none !important;
}