/* Inspiration */
.inspiration.wrapper {
   margin-top: 6.875rem;
}

.inspiration .inspiration__sub-heading {
   margin-top: 1.5rem;
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-normal);
   line-height: 1.25rem;
   color: var(--main-black-color-100);
   display: flex;
   align-items: center;
}

.inspiration .inspiration__sub-heading .line {
   display: inline-block;
   width: 6.25rem;
   height: 0.0625rem;
   background-color: var(--main-black-color-100);
   margin: 0 0.5rem;
   margin-top: 0.375rem;
}

.inspiration .inspiration__img {
   margin-bottom: 3.125rem;
   width: 100%;
   aspect-ratio: 173/100;
}

.inspiration .inspiration__img-2 {
   aspect-ratio: 1/1;
}

.inspiration .inspiration__img img,
.inspiration .inspiration__img-2 img {
   width: 100%;
   height: 100%;
   vertical-align: middle;
   object-fit: cover;
}

.inspiration .inspiration__info {
   padding-top: 0;
}

.inspiration .inspiration__info .heading {
   margin-bottom: 1rem;
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-medium);
   line-height: 1.25rem;
   color: var(--main-black-color-100);
}

.inspiration .inspiration__info .sub-heading {
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-xsmall);
   line-height: 1.125rem;
   color: var(--main-black-color-100);
   margin-bottom: 1.5rem;
}

.inspiration .inspiration__info p {
   margin-bottom: 0.5rem;
   font-family: "Gilroy-Regular";
   font-size: var(--fs-xsmall);
   line-height: 1;
   color: var(--sub-heading-color);
}

/* Location map */
.location .location__header {
   margin-bottom: 1rem;
}

.location .location__header-heading {
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-medium);
   line-height: 1.5rem;
   margin-bottom: 0;
   color: var(--main-black-color);
}

.location .content {
   width: 100%;
   height: 16.5625rem;
   position: relative;
}

.location .location__img {
   width: 100%;
   height: 100%;
   overflow: hidden;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 1;
}

.location .location__img-bg {
   width: 100%;
   height: 100%;
   vertical-align: middle;
   cursor: pointer;
   object-fit: cover;
}

.location iframe {
   position: absolute;
   top: calc(-16.66665% - 0.1875rem);
   left: calc(-16.66665% - 0.1875rem);
   width: calc(133.333333% + 0.375rem);
   height: calc(133.333333% + 0.375rem);
   z-index: 2;
   transition: all ease-in-out 0.4s;
   cursor: pointer;
   transform: scale(0.75);
}

.location .location__address {
   position: absolute;
   top: 0.625rem;
   left: 0.625rem;
   z-index: 10;
   border-radius: 1rem;
   padding: 1rem;
   overflow: hidden;
   border: 0.0625rem solid var(--main-yellow-color-70);
   display: none;
}

.location .location__address-noise {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: -1;
   background-color: var(--main-bg-color-30);
   backdrop-filter: blur(5px);
}

.location .location__address-text {
   width: 100%;
   height: 100%;
}

.location .location__address-text h5 {
   font-family: "Gilroy-Regular";
   font-size: var(--fs-xsmall);
   letter-spacing: 0.0625rem;
   margin: 0.5rem 0;
   color: var(--main-black-color-100);
}

.location .location__address-text h5:first-child {
   margin-top: 0;
}

.location .location__address-text h5:last-child {
   margin-bottom: 0;
}

.location .location__carousel {
   position: absolute;
   bottom: -1rem;
   right: 0;
   left: auto;
   z-index: 10;
   padding: 0.625rem;
   width: 15rem;
}

.location .location__carousel .carousel-inner,
.location .location__carousel .carousel-item {
   width: fit-content;
}

.location .location__carousel .heading {
   font-family: "Gilroy-Heavy";
   font-size: 2.5rem;
   line-height: 5.25rem;
   color: var(--main-black-color-100);
}

.location .location__carousel .text-stroke {
   color: transparent;
}

.location .location__carousel .heading.text-stroke {
   -webkit-text-stroke-color: var(--main-black-color-100);
   -moz-text-stroke-color: var(--main-black-color-100);
   -ms-text-stroke-color: var(--main-black-color-100);
}

/* Responive */
@media (min-width: 576px) {
}

@media (min-width: 768px) {
   .inspiration.wrapper {
      margin-top: 15rem;
   }

   .inspiration .inspiration__sub-heading {
      font-size: var(--fs-bold);
      line-height: 2.25rem;
   }

   .inspiration .inspiration__sub-heading .line {
      width: 12.5rem;
   }

   .inspiration .inspiration__info {
      padding: 0 2.9375rem;
   }

   .inspiration .inspiration__info .heading {
      margin-bottom: 2rem;
      padding: 0.5rem;
      font-family: "Gilroy-Bold";
      font-size: var(--fs-xbold);
      line-height: 3.5625rem;
   }

   .inspiration .inspiration__info .sub-heading {
      font-size: var(--fs-medium);
      line-height: 1.875rem;
      margin-bottom: 4rem;
   }

   .inspiration .inspiration__info p {
      margin-bottom: 1rem;
      font-size: var(--fs-medium);
   }

   .location .location__header {
      margin-bottom: 2rem;
      padding: 0.5rem;
   }

   .location .location__header-heading {
      font-family: "Gilroy-Bold";
      font-size: var(--fs-xbold);
      line-height: 3.5rem;
   }

   .location .content {
      height: 37.5rem;
   }

   .location .location__address-text h5 {
      font-family: "Gilroy-Medium";
      font-size: var(--fs-normal);
   }

   .location .location__carousel {
      bottom: 2rem;
      left: 3.4375rem;
      right: auto;
      width: 28.625rem;
   }

   .location .location__carousel .heading {
      font-size: var(--fs-xlarge);
      color: var(--bg-light);
   }

   .location:hover .location__carousel .heading {
      color: var(--main-black-color-100);
   }

   .location:hover .location__carousel .heading.text-stroke {
      color: transparent;
      -webkit-text-stroke-color: var(--main-black-color-100);
      -moz-text-stroke-color: var(--main-black-color-100);
      -ms-text-stroke-color: var(--main-black-color-100);
   }
}

@media (min-width: 900px) {
   .location .location__address {
      padding: 2rem;
      top: 4.25rem;
      left: 3.4375rem;
      display: block;
   }

   .location iframe {
      top: 100%;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      transform: scale(1);
   }

   .location .content:hover iframe {
      top: -0.125rem;
   }
}

@media (min-width: 1200px) {
}

@media (min-width: 1778px) {
}

/* Css hide license */
.inspiration__info.hide-license p {
   display: none !important;
}