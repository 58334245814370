.header__logo svg {
   width: fit-content !important;
   height: 60px !important;
   fill: #f8f9fa;
}

header.active .header__logo svg {
   fill: #111812;
}

@media (max-width: 768px) {
   .header__logo svg {
      width: max-content !important;
      height: 1.875rem !important;
      fill: #111812 !important;
   }

   header .wrapper {
      height: 3.125rem !important;
   }
}