a{
   text-decoration: none!important;
}

h1,h2,h3,h4,h5,h6{
   margin-bottom: 0;
}

ul {
   margin-bottom: 0 !important;
   padding-left: 0 !important;
}