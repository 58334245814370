
.quote-banner.banner .banner__wrap {
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 13;
   transform: translate(-50%, -50%);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 2rem 1rem;
   width: 100%;
   max-width: 25.625rem;
}

.quote-banner.banner .banner__text {
   width: 100%;
   position: unset;
   margin-bottom: 5rem;
   transform: none;
}

.quote-banner.banner .banner__text .banner__text-top .text-uppercase {
   text-align: start !important;
   font-family: "Gilroy-Bold";
   font-size: 2rem;
   line-height: 2.25rem;
}

.quote-banner.banner .banner__text .banner__text-bot .text-uppercase {
   text-align: start !important;
   font-family: "Gilroy-Medium";
   font-size: 1.125rem;
   line-height: 1.375rem;
   margin-top: 1.5rem;
} 

.quote-banner.banner.banner-image-active .banner__bg {
   display: block;
   height: 100vh;
}

.quote-banner.banner.banner-image-active .banner__img {
   width: 100%;
   aspect-ratio: 173/100;
   object-fit: cover;
}

.quote header .header__nav .header__nav-link a {
   color: var(--main-black-color-100);
}

/* Story */
.quote .story .story__content-img {
   aspect-ratio: 17/10;
   display: flex;
   justify-content: center;
   align-items: center;
   order: -1;
}

.quote .story .story__content-img img {
   vertical-align: middle;
   width: 100%;
}

.quote .story .story__content-text p {
   font-family: 'Gilroy-Regular';
   font-size: var(--fs-xsmall);
   line-height: 1.875rem;
   color: var(--main-black-color-100);
   margin-bottom: 1rem;
   /* overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical; */
}

.quote .story .story__content-text p:last-child {
   font-family: 'Gilroy-SemiBold';
   margin-bottom: 0;
}


/* Quote */
.quote .quote__nav {
   width: 100%;
   height: 2.1875rem;
   overflow-x: auto;
   user-select: none;
   position: relative;
}

.quote .quote__nav::-webkit-scrollbar {
   display: none;
}

.quote .quote__nav .quote__nav-wrap {
   width: max-content;
   min-width: 100%;
   height: 100%;
   border-bottom: 0.125rem solid var(--main-black-color-100);
}

.quote .quote__nav .quote__nav-item {
   margin: 0 1.5rem;
   width: fit-content;
   height: 2rem;
   padding-bottom: 0.25rem;
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-medium);
   color: var(--main-black-color-100);
   line-height: 2rem;
   cursor: pointer;
   display: inline-block;
   text-wrap: nowrap;
   user-select: none;
   position: relative;
   transition: all ease-in-out 0.3s;
}

.quote .quote__nav .quote__nav-item::after {
   content: "";
   display: block;
   height: 0.3rem;
   width: 100%;
   visibility: hidden;
   position: absolute;
   top: calc(100% + 0.1875rem);
   left: 0;
   z-index: 10;
   transition: all ease-in-out 0.3s;
   background-color: var(--main-yellow-color-100);
}

.quote .quote__nav .quote__nav-item.active {
   line-height: 1.5rem;
   color: var(--main-yellow-color-100);
   transform: translateY(-0.0625rem);
}

.quote .quote__nav .quote__nav-item.active::after {
   top: 100%;
   visibility: visible;
}

.quote .quote__content {
   margin-top: 4rem;
}

.quote .quote__content .quote__content-item {
   width: 27.5rem;
   max-width: unset;
}

.quote .quote__content .pack {
   border-radius: 1.5rem;
   background-color: var(--bg-light);
   box-shadow: 0 0.125rem 0.375rem 0.0625rem var(--main-black-color-10);
}

.quote .quote__content .pack .pack__header {
   padding-top: 1rem;
}

.quote .quote__content .pack .pack__header img {
   width: 6.25rem;
   height: 6.25rem;
}

.quote .quote__content .pack .pack__header-info {
   margin-top: 0.5rem;
}

.quote .quote__content .pack .pack__header-info h4 {
   height: 2.25rem;
   margin-bottom: 0;
   font-family: "Gilroy-SemiBold";
   font-size: 1.5rem;
   line-height: 2.25rem;
   color: var(--main-black-color-100);
}

.quote .quote__content .pack__header-info .price {
   width: 100%;
   padding: 0.5rem 1rem;
   border-radius: 0.5rem;
   background-color: #f0a900;
   color: var(--bg-light);
}

.quote .quote__content .pack__header-info .price p:nth-child(1) {
   margin-top: auto;
   font-family: "Gilroy-SemiBold";
   font-size: 1.25rem;
   line-height: 1;
   padding-right: 0.5rem;
   padding-bottom: 0.125rem;
}

.quote .quote__content .pack__header-info .price p:nth-child(2) {
   font-family: "Gilroy-Bold";
   font-size: 2rem;
   line-height: 1;
}

.quote .quote__content .pack__header-info .price label {
   font-family: "Gilroy-Bold";
   font-size: 0.9375rem;
   line-height: 1;
}

.quote .quote__content .pack__header-text {
   width: 100%;
   padding: 1.5rem 0;
   margin: 0 1.5rem;
   font-family: "Gilroy-Medium";
   font-size: 1.25rem;
   color: var(--main-black-color-100);
   line-height: 1.625rem;
   border-bottom: 0.0625rem solid var(--sub-heading-color-30);
   text-align: center;
}

.quote .quote__content .pack__content {
   padding: 2rem 0 0;
   margin: 0 1.5rem;
   border-bottom: 0.0625rem solid var(--sub-heading-color-30);
}

.quote .quote__content .pack__content li span.line {
   width: 2rem;
   height: 0.25rem;
   margin: 0.6875rem auto;
   border-radius: 0.125rem;
   background-color: var(--sub-heading-color);
   text-align: center;
}

.quote .quote__content .pack__content ul li {
   display: flex;
   align-items: start;
   font-family: "Gilroy-Regular";
   font-size: 1rem;
   line-height: 1.5rem;
   color: var(--main-black-color-100);
   margin-bottom: 0.5rem;
}

.quote .quote__content .pack__content ul .icon {
   flex-shrink: 0;
   padding: 0.25rem 0;
   width: 1rem;
   height: 1rem;
   margin-top: 0.375rem;
   margin-right: 1rem;
   border-radius: 50%;
   background-color: var(--main-yellow-color-100);
   border: 0.25rem solid #ffe2bc;
}

.quote .quote__content .pack__btn {
   padding: 1.5rem;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.quote .quote__content .pack__btn a {
   display: block;
   width: fit-content;
   border-radius: 0.5rem;
   padding: 0 1rem;
   background-color: var(--main-yellow-color-100);
   border: none;
   font-family: "Gilroy-Medium";
   font-size: 1rem;
   line-height: 2.375rem;
   height: 2.5rem;
   color: var(--bg-light);
}

.quote .quote__detail {
   margin-top: 8.75rem;
}

.quote .quote__detail-heading {
   margin-bottom: 2.5rem;
   font-family: "Gilroy-Bold";
   font-size: 2.375rem;
   line-height: 3.5625rem;
   color: var(--main-black-color-100);
   text-align: center;
}

.quote .quote__detail-des {
   text-align: center;
   margin: 0 auto;
   width: 53.125rem;
   max-width: 100%;
   font-family: "Gilroy-Regular";
   font-size: 1.25rem;
   line-height: 1.875rem;
   color: var(--sub-heading-color);
   padding-bottom: 4rem;
}

.quote .quote__detail-table .table__header {
   margin-top: 0;
   margin-bottom: 0.5rem;
}

.quote .quote__detail-table .table__header-item {
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: "Gilroy-SemiBold";
   font-size: 1.5rem;
   font-size: 1rem;
   line-height: 2.25rem;
   color: var(--main-black-color-100);
   background-color: var(--bg-light);
}

.quote .quote__detail-table .table__header-item img {
   height: 3.75rem;
   aspect-ratio: 107/100;
   margin-right: 1rem;
}

.quote .quote__detail-table .table__row {
   margin-top: 0;
   margin-bottom: 0;
}

.quote .quote__detail-table .table__row .table__row-item {
   padding: 1rem;
   border-bottom: 0.125rem solid #fff;
   min-height: 5.5rem;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis;
   font-family: "Gilroy-Medium";
   font-size: 0.9375rem;
   line-height: 1rem;
   color: var(--main-black-color-100);
   display: flex;
   align-items: center;
}

.quote .quote__detail-table .table__row .table__row-item.item-check {
   display: flex;
   align-items: center;
   justify-content: center;
}

.quote .quote__detail-table .table__row:nth-child(odd) .table__row-item {
   background-color: var(--bg-light);
}

.quote .quote__detail-table .table__row:nth-child(even) .table__row-item {
   background-color: #f2f2f2;
}

.quote .quote__detail-table.mobile {
   width: 100%;
   overflow: hidden;
}

.quote .quote__detail-table.mobile .table__header .rectangle-33:first-child {
   padding-right: 0.1875rem;
}

.quote .quote__detail-table.mobile .table__header .rectangle-33:last-child {
   padding-left: 0.1875rem;
}

.quote .quote__detail-table.mobile .table__header-item {
   cursor: pointer;
   text-align: center;
}

.quote .quote__detail-table.mobile .table__header-item.active {
   background-color: var(--main-yellow-color-100);
   color: var(--bg-light);
}

.quote .quote__detail-table .table-mobile {
   display: flex;
   align-items: start;
   transform: translateX(0);
   transition: all ease-in-out 0.35s;
}

.quote .quote__detail-table .table-mobile .table-wrap {
   width: 100%;
}

.quote .quote__detail-table .table-mobile .table__row .rectangle-50:first-child {
   padding-right: 0.25rem;
}

.quote .quote__detail-table .table-mobile .table__row .rectangle-50:last-child {
   padding-left: 0.25rem;
}

/* Work */
.quote .work .wrapper__header {
   margin-bottom: 5rem;
}

.quote .work .work__content .project {
   aspect-ratio: 1/1;
   overflow: hidden;
   position: relative;
}

.quote .work .work__content .project:hover .project__des {
   left: 1.625rem;
   bottom: 1.625rem;
}

.quote .work .work__content .project:hover .project__des-icon {
   height: 2rem;
}

.quote .work .work__content .project .project__des {
   position: absolute;
   width: calc(100% - 2rem);
   left: 1rem;
   bottom: 1rem;
   transition: all ease-in-out 0.35s;
   display: flex;
   justify-content: space-between;
}

.quote .work .work__content .project .project__des-text {
   flex: 1;
   color: var(--bg-light);
   font-family: 'Gilroy-SemiBold';
   font-size: var(--fs-bold);
   line-height: 2.25rem;
   transition: all ease-in-out 0.35s;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.quote .work .work__content .project .project__des-icon {
   color: var(--bg-light);
   align-self: flex-end;
   height: 2.75rem;
   margin-left: 1rem;
   padding: 0 0.625rem;
   transition: all ease-in-out 0.35s;
}

.quote .work .work__content .project .project__des-icon .arrow-right-translate {
   height: 100%;
}

.quote .work .work__content .project .arrow-right-translate .arrow-right-translate__line {
   width: calc(100% - 3px);
}

.quote .work .work__content .project .arrow-right-translate .arrow-right-translate__right {
   opacity: 1;
}

.quote .work .work__link {
   margin-top: 4rem;
}


/* Responive */
@media (min-width: 576px) {
   .quote .quote__content .pack .pack__header-info {
       padding-left: 1rem;
   }
}

@media (min-width: 768px) {
   .quote-banner.banner {
      margin-top: 6.25rem;
      height: calc(100vh - 6.25rem);
   }

   .quote-banner.banner .banner__wrap {
      max-width: 53.125rem;
      padding: 3rem;
   }

   .quote-banner.banner .banner__text .banner__text-bot {
      padding-left: 0;
   }

   .quote-banner.banner .banner__text .text-stroke {
      margin: 0;
      padding: 0;
   }

   .quote .story .story__content-img {
       order: 1;
   }

   .quote .story .story__content-text p {
       font-size: var(--fs-medium);
       margin-bottom: 2rem;
       /* -webkit-line-clamp: 3; */
   }

   .quote .quote__detail-table .table__header-item {
       font-size: 1.5rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
   }

   .quote .quote__detail-table .table__row .table__row-item {
       font-size: 1.125rem;
       line-height: 1.1875rem;
       padding: 1.5rem 2rem;
   }
}

@media (min-width: 900px) {

}

@media (min-width: 1200px) {
   .quote-banner.banner .banner__wrap {
      flex-direction: row;
      max-width: 108.125rem;
      padding: 1.5rem;
   }

   .quote-banner.banner .banner__text {
      width: 40%;
   }

   .quote-banner.banner.banner-image-active .banner__img {
      width: 60%;
      padding-left: 4rem;
   }

   .quote-banner.banner .banner__text .banner__text-top .text-uppercase {
      font-size: 3rem;
      line-height: 3.125rem;
   }

   .quote-banner.banner .banner__text .banner__text-bot .text-uppercase {
      font-size: 1.5rem;
      line-height: 1.75rem;
   }
}

@media (min-width: 1360px) {
   .quote .quote__content .quote__content-item:nth-child(2) {
       order: 2 !important;
   }

   .quote .quote__content .quote__content-item:nth-child(3) {
       order: 3 !important;
   }
}

@media (min-width: 1778px) {
   .quote-banner.banner .banner__wrap {
      padding: 0;
   }
}

@media (min-width: 900px) and (max-width: 1359px) {
   .quote .quote__content .quote__content-item:nth-child(1) {
      order: 1;
   }

   .quote .quote__content .quote__content-item:nth-child(2) {
      order: 3;
   }

   .quote .quote__content .quote__content-item:nth-child(3) {
      order: 2;
   }
}