/* Animation icon rotate:
    Class to active: "plus-rotate-icon-active"

    <div class="plus-rotate-icon">
        <i class="left"></i>
        <i class="right"></i>
    </div>
*/
.plus-rotate-icon {
   position: relative;
   width: 16px;
   height: 16px;
   margin-top: 1px;
   background-color: transparent;
   --icon-color: var(--main-black-color-100);
   --width: 14px;
   --height: 2px;
   --translate: calc(0.25 * var(--width) - 0.25 * var(--height));
   cursor: pointer;
}

.plus-rotate-icon .left {
   position: absolute;
   top: calc(50% - 0.5 * var(--height));
   left: calc(50% - 0.5 * var(--width));
   width: var(--width);
   height: var(--height);
   background-color: var(--icon-color);
   transition: all ease-in-out 0.35s;
   transform: rotate(-90deg);
   margin: 0 !important;
   padding: 0 !important;
}

.plus-rotate-icon .right {
   position: absolute;
   top: calc(50% - 0.5 * var(--height));
   left: calc(50% - 0.5 * var(--width));
   width: var(--width);
   height: var(--height);
   background-color: var(--icon-color);
   transition: all ease-in-out 0.35s;
   margin: 0 !important;
   padding: 0 !important;
}

.plus-rotate-icon-active .plus-rotate-icon .left {
   transform: rotate(-135deg)
      translate(var(--translate), calc(0px - var(--translate)));
}

.plus-rotate-icon-active .plus-rotate-icon .right {
   transform: rotate(-45deg) translate(var(--translate), var(--translate));
}

.plus-rotate-icon:hover .left,
.plus-rotate-icon:hover .right {
   background-color: var(--main-yellow-color-100);
}
/* End: Animation icon rotage */

/* Animation circle rotate icon
   Class to active: "circle-rotate-icon-active"

   <div class="circle-rotate-icon">
       <div class="circle-icon"></div>
   </div>
*/
.circle-rotate-icon {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 10px;
   height: 10px;
   background-color: transparent;
   cursor: pointer;
}

.circle-rotate-icon .circle-icon {
   width: 10px;
   height: 10px;
   background-color: var(--main-black-color-100);
   border-radius: 50%;
   transition: all ease-in-out 0.35s;
}

.circle-rotate-icon-active .circle-rotate-icon .circle-icon {
   background-color: var(--main-yellow-color-100);
   border-radius: 0%;
   transform: rotate(45deg);
}

.circle-rotate-icon:hover .circle-icon {
   background-color: var(--main-yellow-color-100);
}
/* End: Animation circle rotate icon */

/* Animation text-strock: 
   <div class="text-stroke">This is title</div>
*/
.text-stroke {
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: var(--bg-light);
   -moz-text-stroke-width: 1px;
   -moz-text-stroke-color: var(--bg-light);
   -ms-text-stroke-width: 1px;
   -ms-text-stroke-color: var(--bg-light);
   color: transparent;
   transition: all ease-in-out 0.6s;
}

.text-stroke:hover {
   -webkit-text-stroke-color: transparent;
   -moz-text-stroke-color: transparent;
   -ms-text-stroke-color: transparent;
   color: var(--bg-light);
}
/* End: Animation text-strock */

/* Animation filter image black and white 
   Class to active: "img-grayscale-hover"

   <div class="img-grayscale"><img src="" alt="black and white image"></div>
*/
.img-grayscale {
   transition: all ease-in-out 0.35s;
}

.img-grayscale img {
   width: 100%;
   height: 100%;
   filter: grayscale(100%);
   -webkit-filter: grayscale(100%);
   -moz-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
   -o-filter: grayscale(100%);
   vertical-align: middle;
   cursor: pointer;
}

.img-grayscale-hover:hover .img-grayscale {
   transform: scale(1.025);
}

.img-grayscale-hover:hover .img-grayscale img {
   filter: grayscale(0);
   -webkit-filter: grayscale(0);
   -moz-filter: grayscale(0);
   -ms-filter: grayscale(0);
   -o-filter: grayscale(0);
}

/* End: Animation filter image black and white  */

/* Animation Change link arrow right 
   <a class="arrow-right-link" href="">
       <div class="arrow-right-link__text">Go to About</div>
       <i class="arrow-right-link__icon fa-solid fa-arrow-right-long"></i>
   </a>
*/
.arrow-right-link {
   width: fit-content;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;
}

.arrow-right-link .arrow-right-link__text {
   padding: 0.625rem;
   font-family: "Gilroy-Regular";
   font-size: var(--fs-small);
   line-height: 1.5rem;
   color: var(--main-black-color-100);
   transition: all ease-in-out 0.25s;
}

.arrow-right-link .arrow-right-link__icon {
   padding: 0.625rem 1rem;
   padding-top: 0.875rem;
   color: var(--main-black-color-100);
   transition: all ease-in-out 0.25s;
}

.arrow-right-link:hover .arrow-right-link__text {
   color: var(--main-yellow-color-100);
}

.arrow-right-link:hover .arrow-right-link__icon {
   transform: translateX(0.375rem);
   color: var(--main-yellow-color-100);
}
/* End: Animation change icon arrow right */

/* Animation arrow right translateX 
   Class to active: "arrow-right-translate-hover"

   <div class="arrow-right-translate">
       <div class="arrow-right-translate__line"></div>
       <div class="arrow-right-translate__right fa-solid fa-angle-right"></div>
   </div>
*/
.arrow-right-translate {
   width: 1.75rem;
   height: 1rem;
   position: relative;
   cursor: pointer;
}

.arrow-right-translate .arrow-right-translate__line {
   position: absolute;
   top: calc(50% - 1px);
   left: 0;
   width: 0;
   height: 2px;
   background-color: var(--bg-light);
   transition: all ease-in-out 0.35s;
}

.arrow-right-translate .arrow-right-translate__right {
   position: absolute;
   top: calc(50% - 6px);
   right: 0;
   width: fit-content;
   height: 12px;
   font-size: 13px;
   line-height: 12px;
   color: var(--bg-light);
   opacity: 0;
   transition: all ease-in-out 0.35s;
}

.arrow-right-translate-hover:hover
   .arrow-right-translate
   .arrow-right-translate__line {
   width: calc(100% - 3px) !important;
}

.arrow-right-translate-hover:hover
   .arrow-right-translate
   .arrow-right-translate__right {
   opacity: 1 !important;
}
/* End: Animation change icon arrow right */

/* Animation Accordion collapse 
   <div class="accordion" id="accordion-service">
       <div class="accordion-item service">
           <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-service-1">
               <div class="d-flex align-items-center justify-content-between w-100 h-100">
                   <div class="accordion-button__left">
                       <div class="circle-rotate-icon">
                           <div class="circle-icon"></div>
                       </div>
                   </div>

                   <div class="accordion-button__title">
                       <h4>Branding</h4>
                   </div>

                   <div class="accordion-button__right">
                       <div class="plus-rotate-icon">
                           <i class="left"></i>
                           <i class="right"></i>
                       </div>
                   </div>
               </div>
           </button>

           <div class="accordion-collapse collapse" id="collapse-service-1" data-bs-parent="#accordion-service">
               <div class="accordion-body">
                   Collapse here
               </div>
           </div>
       </div>
   </div>
*/
.accordion .accordion-item {
   background-color: transparent;
   border: none;
   border-radius: 0;
   border-bottom: 0.125rem solid var(--main-black-color-100);
}

.accordion .accordion-item .accordion-button {
   background-color: transparent;
   box-shadow: none;
   height: 5rem;
   padding: 0;
}

.accordion .accordion-item .accordion-button::after {
   display: none;
}

.accordion .accordion-item .accordion-button .accordion-button__left {
   padding-left: 1rem;
   padding-right: 2.5rem;
}

.accordion .accordion-item .accordion-button .accordion-button__title {
   flex: 1;
   padding: 0 0.625rem;
}

.accordion .accordion-item .accordion-button .accordion-button__title h4 {
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-xsmall);
   line-height: 1.875rem;
   color: var(--main-black-color-100);
   transition: all ease-in-out 0.25s;
   width: fit-content;
}

.accordion .accordion-item .accordion-button .accordion-button__title h4:hover {
   color: var(--main-yellow-color-100);
}

.accordion .accordion-item .accordion-button .accordion-button__right {
   padding-right: 1rem;
   height: 1.0625rem;
}

.accordion .accordion-item .accordion-body {
   padding: 0;
}
/* End: Animation accordion collapse */

/* Animation scroll partners 
   <div class="partners"> 
       <div class="wrapper">
           <div class="scroll-wrapper">
               <div class="scroll-wrapper__content">
                   <div class="scroll-wrapper__content-item"><img src="" alt=""></div>
               </div>
           </div>

           <div class="dots">
               <div class="dot"></div>
               <div class="dot"></div>
           </div>
       </div>
   </div>
*/
.partners {
   background-color: var(--main-yellow-color-100);
}

.partners .scroll-wrapper {
   margin-bottom: 1rem;
   width: 100%;
   /* overflow: auto; */
   overflow: hidden;
}

.partners .scroll-wrapper .scroll-wrapper__content-item {
   flex-direction: column;
   flex: 0 0 auto;
   width: fit-content;
}

.partners .scroll-wrapper .scroll-wrapper__content-item img {
   margin: 0 0.75rem;
   width: 6.25rem;
   aspect-ratio: 5/3;
   vertical-align: middle;
   pointer-events: none;
   user-select: none;
}

.partners .scroll-wrapper .scroll-wrapper__content-item:first-child img {
   margin-left: 0;
}

.partners .scroll-wrapper .scroll-wrapper__content-item:last-child img {
   margin-right: 0;
}

.partners .scroll-wrapper .scroll-wrapper__content-item img:nth-child(1) {
   margin-bottom: 0.75rem;
}

.partners .scroll-wrapper .scroll-wrapper__content-item img:nth-child(2) {
   margin-top: 0.75rem;
}

/* Hide scrollbar */
.partners .scroll-wrapper::-webkit-scrollbar {
   display: none;
}

.partners .scroll-wrapper::-webkit-scrollbar-thumb {
   display: none;
}
/* End: Hide scrollbar */

.partners .scroll-wrapper .scroll-wrapper__content {
   width: max-content;
   display: flex;
   align-items: center;
   white-space: nowrap;
   pointer-events: none;
   user-select: none;
   scrollbar-gutter: none;
   transform: translateX(0px);
}

.dots {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px 0;
}

.dots .dot {
   margin: 0 16px;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   border: 2px solid var(--main-yellow-color-100);
   background-color: var(--main-bg-color-30);
   transition: all ease-in-out 0.25s;
   cursor: pointer;
}

.dots .dot.active {
   border: 2px solid var(--main-bg-color-100);
   background-color: transparent;
}

.dots .dot:not(.active):hover {
   background-color: var(--main-bg-color-100);
}
/* End: Animation scroll */

/* Animation banner color */
.banner {
   width: 100%;
   height: 100vh;
   position: relative;
   overflow: hidden;
   background-color: var(--main-bg-color-30);
}

.banner .banner__bg {
   width: 100%;
   height: 100%;
   background-color: var(--main-yellow-color-100);
   position: relative;
   z-index: 10;
}

.banner .banner__bg .banner__bg-eclipse1,
.banner .banner__bg .banner__bg-eclipse2,
.banner .banner__bg .banner__bg-eclipse3 {
   filter: blur(50px);
}

.banner .banner__bg .banner__bg-eclipse1 {
   width: 110%;
   aspect-ratio: 1/1;
   border-radius: 50%;
   background-color: #ffcd0e;
   position: absolute;
   z-index: 8;
   transform: translate(-50%, -50%);
   top: 70%;
   left: 50%;
   animation: trans1 ease-in-out 18s forwards infinite;
}

.banner .banner__bg .banner__bg-eclipse2 {
   width: 65%;
   aspect-ratio: 1/1;
   border-radius: 50%;
   background-color: #f09e00;
   position: absolute;
   z-index: 9;
   transform: translate(-50%, -50%);
   top: 30%;
   left: 90%;
   animation: trans2 ease-in-out 18s forwards infinite;
}

.banner .banner__bg .banner__bg-eclipse3 {
   width: 40%;
   aspect-ratio: 1/1;
   border-radius: 50%;
   background-color: #ed8d1a;
   position: absolute;
   z-index: 7;
   transform: translate(-50%, -50%);
   top: 15%;
   left: 10%;
   animation: trans3 ease-in-out 18s forwards infinite;
}

@keyframes trans1 {
   0% {
      top: 70%;
      left: 50%;
   }
   20% {
      top: 40%;
      left: 15%;
   }
   30% {
      top: 40%;
      left: 15%;
   }
   60% {
      top: 90%;
      left: 80%;
   }
   70% {
      top: 90%;
      left: 80%;
   }
   90% {
      top: 70%;
      left: 50%;
   }
   100% {
      top: 70%;
      left: 50%;
   }
}

@keyframes trans2 {
   0% {
      top: 30%;
      left: 90%;
   }
   20% {
      top: 80%;
      left: 45%;
   }
   30% {
      top: 80%;
      left: 45%;
   }
   60% {
      top: 60%;
      left: 10%;
   }
   70% {
      top: 60%;
      left: 10%;
   }
   90% {
      top: 30%;
      left: 90%;
   }
   100% {
      top: 30%;
      left: 90%;
   }
}

@keyframes trans3 {
   0% {
      top: 15%;
      left: 10%;
   }
   20% {
      top: 60%;
      left: 90%;
   }
   30% {
      top: 60%;
      left: 90%;
   }
   60% {
      top: 20%;
      left: 60%;
   }
   70% {
      top: 20%;
      left: 60%;
   }
   90% {
      top: 15%;
      left: 10%;
   }
   100% {
      top: 15%;
      left: 10%;
   }
}
/* End: Banner */

/* Responive */
@media (min-width: 576px) {
   .banner .banner__bg .banner__bg-eclipse1 {
      width: 85%;
   }

   .banner .banner__bg .banner__bg-eclipse2 {
      width: 60%;
   }

   .banner .banner__bg .banner__bg-eclipse3 {
      width: 35%;
   }
}

@media (min-width: 768px) {
   .plus-rotate-icon {
      --width: 16px;
   }

   .arrow-right-link .arrow-right-link__text {
      font-size: var(--fs-medium);
   }

   .accordion .accordion-item .accordion-button .accordion-button__right {
      padding-right: 4rem;
   }

   .accordion .accordion-item .accordion-button {
      height: 5.9375rem;
   }

   .accordion .accordion-item .accordion-button .accordion-button__title h4 {
      font-family: "Gilroy-Medium";
      font-size: var(--fs-medium);
   }

   /* Partners */
   .partners .wrapper .dots {
      margin-top: 3.4375rem;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item img {
      width: 12.5rem;
      margin: 0 1.875rem;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item:first-child img {
      margin-left: 0;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item:last-child img {
      margin-right: 0;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item img:nth-child(1) {
      margin-bottom: 1.875rem;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item img:nth-child(2) {
      margin-top: 1.875rem;
   }

   .banner .banner__bg .banner__bg-eclipse1 {
      background-color: #eebd00;
   }

   .banner .banner__bg .banner__bg-eclipse2 {
      background-color: #f09e00;
   }

   .banner .banner__bg .banner__bg-eclipse3 {
      background-color: #f09600;
   }

   .banner .banner__bg .banner__bg-eclipse1,
   .banner .banner__bg .banner__bg-eclipse2,
   .banner .banner__bg .banner__bg-eclipse3 {
      filter: blur(100px);
   }
}

@media (min-width: 900px) {
   .banner .banner__bg .banner__bg-eclipse1,
   .banner .banner__bg .banner__bg-eclipse2,
   .banner .banner__bg .banner__bg-eclipse3 {
      filter: blur(200px);
   }
}

@media (min-width: 1200px) {
   .banner .banner__bg .banner__bg-eclipse1 {
      width: 60%;
   }

   .banner .banner__bg .banner__bg-eclipse2 {
      width: 45%;
   }

   .banner .banner__bg .banner__bg-eclipse3 {
      width: 35%;
   }
}

@media (min-width: 1778px) {
}
