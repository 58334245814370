
/* Video autoplay */
.reels {
   width: 100%;
   aspect-ratio: 16/9;
   max-height: 100vh;
}

.reels video {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

/* Story */
.story .story__content .label {
   padding: 0;
   padding-bottom: 1rem;
}

.story .story__content .label__text {
   font-family: "Gilroy-Medium";
   font-size: var(--fs-normal);
   color: var(--main-black-color-100);
}

.story .story__content .label__icon {
   width: 12.6875rem;
   height: 0.0625rem;
   margin-left: 0;
   margin-right: 1.625rem;
   margin-top: 0.1875rem;
   background-color: var(--main-black-color-100);
   order: -1;
}

.story .story__content .content__text p {
   font-family: "Gilroy-Regular";
   font-size: var(--fs-xsmall);
   line-height: 1.875rem;
   color: var(--main-black-color-100);
   margin-bottom: 1rem;
   /* overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical; */
}

.story .story__content .content__text p:last-child {
   font-family: "Gilroy-SemiBold";
   margin-bottom: 0;
}

.story .story__content .content__link {
   margin-top: 0.875rem;
}

/* Expertise */
.expertise .service .service__content {
   margin: 0 auto;
   padding: 0;
   padding-bottom: 2rem;
   max-width: 100%;
}

.expertise .service .service__content .carousel-item img {
   aspect-ratio: 41/24;
   object-fit: cover;
}

.expertise .service .service__content-text {
   margin-top: 0.25rem;
   padding-top: 1rem !important;
}

.expertise .service .service__content-text h6 {
   font-family: "Gilroy-Regular";
   font-size: var(--fs-small);
   line-height: 1.5rem;
   color: var(--main-black-color-100);
   margin-bottom: 0.5rem;
}

/* Work */
.work .wrapper__header {
   margin-bottom: 5rem;
}

.work .work__content .project {
   aspect-ratio: 1/1;
   overflow: hidden;
   position: relative;
}

.work .work__content .project:hover .project__des {
   left: 1.625rem;
   bottom: 1.625rem;
}

.work .work__content .project:hover .project__des-icon {
   height: 2rem;
}

.work .work__content .project .project__des {
   position: absolute;
   width: calc(100% - 2rem);
   left: 1rem;
   bottom: 1rem;
   transition: all ease-in-out 0.35s;
   display: flex;
   justify-content: space-between;
}

.work .work__content .project .project__des-text {
   flex: 1;
   color: var(--bg-light);
   font-family: "Gilroy-SemiBold";
   font-size: var(--fs-bold);
   line-height: 2.25rem;
   transition: all ease-in-out 0.35s;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.work .work__content .project .project__des-icon {
   color: var(--bg-light);
   align-self: flex-end;
   height: 2.75rem;
   margin-left: 1rem;
   padding: 0 0.625rem;
   transition: all ease-in-out 0.35s;
}

.work .work__content .project .project__des-icon .arrow-right-translate {
   height: 100%;
}

.work
.work__content
.project
.arrow-right-translate
.arrow-right-translate__line {
   width: calc(100% - 3px);
}

.work
.work__content
.project
.arrow-right-translate
.arrow-right-translate__right {
   opacity: 1;
}

.work .work__link {
   margin-top: 4rem;
}

/* Partners */
.partners {
   padding-top: 2rem;
   padding-bottom: 1rem;
}

.partners .wrapper {
   padding: 0 1.3125rem;
}

/* Responive */
@media (min-width: 576px) {
   .partners .wrapper {
      padding: 0 0.9375rem;
   }
}

@media (min-width: 768px) {
   .story .story__content .label {
      padding: 1.25rem 5rem 6.25rem 0;
   }

   .story .story__content .label__text {
      font-family: "Gilroy-Regular";
      font-size: var(--fs-bold);
   }

   .story .story__content .label__icon {
      margin-left: 1.625rem;
      margin-right: 0;
      order: 1;
   }

   .story .story__content .content__text p {
      font-family: "Gilroy-Regular";
      font-size: var(--fs-medium);
      line-height: 1.875rem;
      color: var(--main-black-color-100);
      margin-bottom: 2rem;
      /* -webkit-line-clamp: 3; */
   }

   .story .story__content .content__link {
      margin-top: 3.0625rem;
   }

   .expertise .service .service__content-text {
      padding-top: 0 !important;
   }

   .work
   .work__content
   .project
   .arrow-right-translate
   .arrow-right-translate__line {
      width: 0;
   }

   .work
   .work__content
   .project
   .arrow-right-translate
   .arrow-right-translate__right {
      opacity: 0;
   }

   .partners {
      padding-top: 4rem;
      padding-bottom: 3rem;
   }

   .partners .wrapper {
      padding: 3.4375rem 4.0625rem 0;
   }
}

@media (min-width: 900px) {

}

@media (min-width: 1200px) {

   .partners .wrapper {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item {
      flex-direction: row;
   }

   .partners .scroll-wrapper .scroll-wrapper__content-item img {
      margin: 0 !important;
      margin-right: 3.75rem !important;
   }

   .partners
   .scroll-wrapper
   .scroll-wrapper__content-item:last-child
   img:last-child {
      margin-right: 0 !important;
   }
}

@media (min-width: 1778px) {
   .partners .wrapper {
      padding-left: 0;
      padding-right: 0;
   }
}
