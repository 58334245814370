/* Custom font */
@font-face {
    font-family: Gilroy-Black;
    src: url('../assets/fonts/SVN-Gilroy-Black.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Black-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Black-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Bold;
    src: url('../assets/fonts/SVN-Gilroy-Bold.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Bold-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Bold-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Heavy;
    src: url('../assets/fonts/SVN-Gilroy-Heavy.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Heavy-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Heavy-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Light-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Light-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Light;
    src: url('../assets/fonts/SVN-Gilroy-Light.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Medium-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Medium-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Medium;
    src: url('../assets/fonts/SVN-Gilroy-Medium.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Regular;
    src: url('../assets/fonts/SVN-Gilroy-Regular.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-SemiBold-Italic;
    src: url('../assets/fonts/SVN-Gilroy-SemiBold-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-SemiBold;
    src: url('../assets/fonts/SVN-Gilroy-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Thin-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Thin-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-Thin;
    src: url('../assets/fonts/SVN-Gilroy-Thin.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-XBold-Italic;
    src: url('../assets/fonts/SVN-Gilroy-XBold-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-XBold;
    src: url('../assets/fonts/SVN-Gilroy-XBold.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-XLight-Italic;
    src: url('../assets/fonts/SVN-Gilroy-Xlight-Italic.otf') format('opentype');
}

@font-face {
    font-family: Gilroy-XLight;
    src: url('../assets/fonts/SVN-Gilroy-Xlight.otf') format('opentype');
}