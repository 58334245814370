.carousel-indicators {
   display: none !important;
}

.carousel-control-prev,
.carousel-control-next {
   display: none !important;
}

.accordion-body {
   display: flex;
   justify-content: center;
}
.carousel {
   width: auto !important;
}

h6 {
   font-family: "Gilroy-Regular";
   font-size: var(--fs-small);
   line-height: 1.5rem !important;
   color: var(--main-black-color-100);
   margin-bottom: 0.5rem !important;
}

.accordion-item__content h4 {
   font-family: "Gilroy-Medium";
   font-size: var(--fs-medium);
}

.accordion {
   border-bottom: 0.125rem solid var(--main-black-color-100);
}

.accordion-item {
   border-bottom: none !important;
}

.service__button:hover{
   cursor: pointer;
   color: var(--main-yellow-color-100) !important;
   /* color:yellow; */
}

@media (min-width: 1200px) {
   .max-width-none {
      max-width: none !important;
   }
}
